window.addEventListener('userSessionLoaded', () => {
    initSponsor();
});
if (window.gr) {
    initSponsor();
}

function initSponsor() {

    gr.sponsor = {
        base_url: SPONSOR_BASE_URL,
        coupon: {
            cookie_name: 'sponsor_coupon',
            coupon_url:
                typeof SPONSOR_BASE_URL != 'undefined' && typeof LANGUAGE != 'undefined'
                    ? SPONSOR_BASE_URL +
                      'coupons/popup?lang=' +
                      LANGUAGE +
                      '&k=' +
                      new Date().toISOString().substr(0, 10)
                    : 'http://ads.freepik.com/coupons/popup?lang=&k=' + new Date().toISOString().substr(0, 10),
            launch: function(fn) {
                if (typeof disallow_coupon != 'undefined') return false;

                $.get(gr.sponsor.coupon.coupon_url, function(data) {
                    $('#footer').after(data);

                    EventTracker.printGA({
                        type: 'imp',
                        section: 'stockbanner',
                    });

                    if ('function' === typeof fn) {
                        fn();
                    }
                });
            },
        },

        get: function(params) {
            if (typeof params == 'undefined') params = {};
            if (typeof params.container == 'undefined') return false;

            gr.request.get(gr.sponsor.base_url + 'banner/ajax_search', params);
        },
    };
}
