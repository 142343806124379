/* eslint-disable @typescript-eslint/camelcase */
import 'Variables';

import { ww } from 'bobjoll/ts/library/dom';
import { httpBuildQuery } from 'Partials/xhr';
import { isBannedCountry } from 'Project/ts/common-app/sponsor/common/isBannedCountry';
import User from 'Project/ts/pods/user/main';

import { getShutterstockBanner } from './shutter/shutterstock.service';
import { getIstockBanner } from './istock/istock.service';

export class SponsorBanner {
    public static async insert(options: SponsorBannerSettings) {
        if (!SponsorBanner.show()) {
            ww.view.showResults('.icons-search-empty-result');
            return;
        }

        if (undefined === options.page) {
            options.page = Number(SPONSOR_CURRENT_PAGE); // FIXME
        }

        const term = SponsorBanner.addIconFiltersInTermParam(options.term, SPONSOR);

        if (SPONSOR === 'shutterstock') {
            await getShutterstockBanner({...options, term});
        } else if (SPONSOR === 'istock') {
            await getIstockBanner({...options, term});
        } else {
            return;
        }
    }

    private static addIconFiltersInTermParam(term: string, sponsorName: string) {
        const filters: Record<string, string> = {
            adobe: 'icon -set -collection -series -pack -bundle -group',
            istock: 'icon -set -collection -series -pack -bundle -group',
            shutterstock: 'icon NOT set',
          };
          return `${term} ${filters[sponsorName]}`;
    }

    public static getFreepikSponsor(searchText: string) {
        let params = {
            sp: 'freepik',
            searchText: searchText ? searchText : 'icon',
            //'lang' : LANGUAGE,
            cache: SPONSOR_CACHE,
            page: document.getElementById('pagination-page')
                ? (document.getElementById('pagination-page') as HTMLInputElement)?.value
                : 1,
            body_class: 'flaticon_v2_it',
            show_header: 1,
            preload_size: 110,
            img_size: 'img_small',
            min_size: 110,
            max_size: 112,
            min_margin: 5,
            top_margin: 7,
            num_rows: 1,
        };
        let url = SPONSOR_FREEPIK + httpBuildQuery(params);

        return (
            '<section id="sp-freepik"><iframe class="edge-content-iframe" width="100%" height="182" scrolling="no" frameborder="0" marginwidth="0" src="' +
            url +
            '"style="box-sizing: inherit;"></iframe></section>'
        );
    }

    private static show() {
        return !User.isPremium() && window.innerWidth >= 640 && !isBannedCountry();
    }

    public static getVisibleElements() {
        const parentWidth = (document.querySelector('.unicorn__row') as HTMLElement)?.offsetWidth;
        const itemWith = (document.querySelector('.unicorn__row .item') as HTMLElement)?.offsetWidth + 5;
        const numberOfCols = parentWidth / itemWith;

        const visibleElements = document.querySelectorAll(
            `.unicorn__row .item:nth-child(-n+${Math.floor(numberOfCols)})`,
        );

        return visibleElements;
    }
}

const sponsorBanner = new SponsorBanner();
export default sponsorBanner;

export interface SponsorBannerSettings {
    id?: string;
    element: HTMLElement;
    premium?: boolean;
    ignorePremium?: boolean;
    data?: JSON;
    term: string;
    page?: number;
    url?: string;
    globalVariables?: {
        [name: string]: any;
    };
    section?: string;
}